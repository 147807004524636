exports.components = {
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-frei-js": () => import("./../../../src/pages/frei.js" /* webpackChunkName: "component---src-pages-frei-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kindertagespflege-js": () => import("./../../../src/pages/kindertagespflege.js" /* webpackChunkName: "component---src-pages-kindertagespflege-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-referenzen-js": () => import("./../../../src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-tagesmutter-js": () => import("./../../../src/pages/tagesmutter.js" /* webpackChunkName: "component---src-pages-tagesmutter-js" */)
}

