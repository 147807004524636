module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://stats.kleine-wolke-dresden.de","siteUrl":"https://kleine-wolke-dresden.de","matomoPhpScript":"tagespflege","matomoJsScript":"kinderpflege","exclude":["/offline-plugin-app-shell-fallback/"],"requireConsent":false,"requireCookieConsent":true,"disableCookies":true,"cookieDomain":"*.kleine-wolke-dresden.de","enableJSErrorTracking":true,"respectDnt":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kindertagespflege Kleine Wolke Dresden","short_name":"Kleine-Wolke-Dresden","start_url":"/","icon":"static/favicon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f362550cb9955424be4065c3da1079a"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
